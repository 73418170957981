/**
 * Reference: https://gist.github.com/samselikoff/510c020e4c9ec17f1cf76189ce683fa8?permalink_comment_id=5171034#gistcomment-5171034
 *
 * There will be a native implementation in react-router v7.1. I think we don't need this function then anymore.
 * https://github.com/remix-run/react-router/issues/11564#issue-2303616999
 */

import { useFetcher, useLoaderData } from 'react-router';
import { useRef, useCallback, useEffect } from 'react';

type FetcherData<T> = NonNullable<SerializeFrom<T>>;
type ResolveFunction<T> = (value: FetcherData<T>) => void;
type SerializeFrom<T> = ReturnType<typeof useLoaderData<T>>;

export function useFetcherWithPromise<TData>(
    opts?: Parameters<typeof useFetcher>[0]
) {
    const fetcher = useFetcher<TData>(opts);
    const resolveRef = useRef<ResolveFunction<TData>>();
    const promiseRef = useRef<Promise<FetcherData<TData>>>();

    if (!promiseRef.current) {
        promiseRef.current = new Promise<FetcherData<TData>>((resolve) => {
            resolveRef.current = resolve;
        });
    }

    const resetResolver = useCallback(() => {
        promiseRef.current = new Promise((resolve) => {
            resolveRef.current = resolve;
        });
    }, [promiseRef, resolveRef]);

    const submit = useCallback(
        async (...args: Parameters<typeof fetcher.submit>) => {
            fetcher.submit(...args);
            return promiseRef.current;
        },
        [fetcher, promiseRef]
    );

    useEffect(() => {
        if (fetcher.state === 'idle') {
            if (fetcher.data) {
                resolveRef.current?.(fetcher.data);
            }
            resetResolver();
        }
    }, [fetcher, resetResolver]);

    return { ...fetcher, submit };
}
