import { Slide, toast, ToastContainer } from 'react-toastify';
import { ToastServiceInterface } from './toast-service.interface';
import { ToastOptionType } from './toast.config';

export class ToastService implements ToastServiceInterface {
    show(message: string, options: { type: ToastOptionType }): void {
        toast(message, { type: options.type });
    }
    getComponent(): React.ReactNode {
        return (
            <ToastContainer
                position='top-right'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='light'
                transition={Slide}
            />
        );
    }
}
